html,
body,
ol,
ul,
li,
h1,
p {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

a,
img,
button,
input,
textarea,
i,
span,
div {
  -webkit-tap-highlight-color: rgba(255,255,255,0);
}

input,
select,
textarea {
  padding: 0;
  border-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  box-sizing: border-box;
  color: #333;
  font-size: 0.18666667rem;
  -webkit-overflow-scrolling: touch;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  background-color: #232628;
}

body *,
body *::before,
body *::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

.clearfix {
  *zoom: 1;
}

.clearfix::after {
  display: table;
  clear: both;
  line-height: 0;
  content: "";
}

.wrap {
  min-width: 17.06666667rem;
}

.content {
  width: 17.06666667rem;
  margin: 0 auto;
}

.safe-area-inset-bottom {
  border-width: constant(safe-area-inset-bottom);
  border-width: env(safe-area-inset-bottom);
  border-bottom-color: #f7f7f7;
  border-bottom-style: solid;
}

.flex_cen_bet {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.flex_top_bet {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.footer-content {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 0.48rem 1.16rem;
}

.footer-content .btnText {
  width: 2.72rem;
  height: 0.57333333rem;
}

.footer-content .footer-btn {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 1.33333333rem;
  color: #1F1F1F;
  font-size: 0.48rem;
  border: 0.02666667rem solid #1F1F1F;
  border-radius: 0.66666667rem;
  outline: none;
}

.footer-content .footer-btn.footer-btn_2 {
  margin-bottom: 0.48rem;
  background: linear-gradient(177.85deg, #fb7516 0%, #ff6200 100%);
  border-color: transparent;
}

.wx-download-tips {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  background-image: url(https://img10.360buyimg.com/paipai/jfs/t1/219402/23/34952/8222/64be600bFa63750c9/6ca2a91feda6a6fd.webp);
  background-repeat: no-repeat;
  background-size: 6.18666667rem auto;
  background-position: 3.06666667rem 0.4rem;
  overflow: hidden;
}
.index_index__3ejdo {
  position: relative;
}

.index_index__3ejdo .index_header__9MThl {
  position: relative;
  width: 100%;
  height: 17.6rem;
  background-color: #bef000;
  background-image: url("//img10.360buyimg.com/paipai/jfs/t1/145256/15/38134/97293/64bf41c7F1edacc74/ffeca801efa8cf1f.png");
  background-repeat: no-repeat;
  background-position: 0 -0.32rem;
  background-size: cover;
}

.index_index__3ejdo .index_footerBg__73qU3 {
  background-color: #bef000;
}

.index_index__3ejdo .index_footerWrapper__1NBtM {
  width: 100%;
  padding: 0.85333333rem 0.32rem;
  background: linear-gradient(0deg, #232628 0%, #333643 100%);
  border-radius: 0.53333333rem 0.53333333rem 0 0;
}

.index_index__3ejdo .index_footerWrapper__1NBtM .index_titleBox__1c0pC {
  color: #FFF;
  text-align: center;
}

.index_index__3ejdo .index_footerWrapper__1NBtM .index_titleBox__1c0pC .index_titileMain__nxOiQ {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  font-size: 0.56rem;
  font-weight: bold;
}

.index_index__3ejdo .index_footerWrapper__1NBtM .index_titleBox__1c0pC .index_titileMain__nxOiQ::after,
.index_index__3ejdo .index_footerWrapper__1NBtM .index_titleBox__1c0pC .index_titileMain__nxOiQ::before {
  content: '';
  position: absolute;
  width: 0.4rem;
  height: 0.13333333rem;
  background: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.index_index__3ejdo .index_footerWrapper__1NBtM .index_titleBox__1c0pC .index_titileMain__nxOiQ::before {
  left: -0.53333333rem;
}

.index_index__3ejdo .index_footerWrapper__1NBtM .index_titleBox__1c0pC .index_titileMain__nxOiQ::after {
  right: -0.53333333rem;
}

.index_index__3ejdo .index_footerWrapper__1NBtM .index_titleBox__1c0pC .index_titleSub__TlKbb {
  font-size: 0.37333333rem;
  margin-top: 0.02666667rem;
}

.index_index__3ejdo .index_footerWrapper__1NBtM .index_images__1dOMC {
  margin: 0.57333333rem 0 0.85333333rem 0;
}

.index_index__3ejdo .index_footerWrapper__1NBtM .index_images__1dOMC .index_imagesItem__74WS8 {
  margin-bottom: 0.26666667rem;
  border-radius: 0.26666667rem;
}

.index_index__3ejdo .index_footerWrapper__1NBtM .index_beishu__3hBuy {
  width: 100%;
  height: 3.97333333rem;
}

.index_index__3ejdo .index_footerWrapper__1NBtM .index_inspect__2jsWD {
  text-align: center;
}

.index_index__3ejdo .index_footerWrapper__1NBtM .index_inspect__2jsWD .index_logo__1e4ze {
  width: 1.8rem;
  height: 1.8rem;
  margin-bottom: 0.48rem;
}
