.footer-content {
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 0.48rem 1.16rem;
}

.footer-content .btnText {
  width: 2.72rem;
  height: 0.57333333rem;
}

.footer-content .footer-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.33333333rem;
  color: #1F1F1F;
  font-size: 0.48rem;
  border: 0.02666667rem solid #1F1F1F;
  border-radius: 0.66666667rem;
  outline: none;
}

.footer-content .footer-btn.footer-btn_2 {
  margin-bottom: 0.48rem;
  background: linear-gradient(177.85deg, #fb7516 0%, #ff6200 100%);
  border-color: transparent;
}

.wx-download-tips {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
  background-image: url(https://img10.360buyimg.com/paipai/jfs/t1/219402/23/34952/8222/64be600bFa63750c9/6ca2a91feda6a6fd.webp);
  background-repeat: no-repeat;
  background-size: 6.18666667rem auto;
  background-position: 3.06666667rem 0.4rem;
  overflow: hidden;
}