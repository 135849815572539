.index {
  position: relative;
}

.index .header {
  position: relative;
  width: 100%;
  height: 17.6rem;
  background-color: #bef000;
  background-image: url("//img10.360buyimg.com/paipai/jfs/t1/145256/15/38134/97293/64bf41c7F1edacc74/ffeca801efa8cf1f.png");
  background-repeat: no-repeat;
  background-position: 0 -0.32rem;
  background-size: cover;
}

.index .footerBg {
  background-color: #bef000;
}

.index .footerWrapper {
  width: 100%;
  padding: 0.85333333rem 0.32rem;
  background: linear-gradient(0deg, #232628 0%, #333643 100%);
  border-radius: 0.53333333rem 0.53333333rem 0 0;
}

.index .footerWrapper .titleBox {
  color: #FFF;
  text-align: center;
}

.index .footerWrapper .titleBox .titileMain {
  display: inline-block;
  margin: 0 auto;
  position: relative;
  font-size: 0.56rem;
  font-weight: bold;
}

.index .footerWrapper .titleBox .titileMain::after,
.index .footerWrapper .titleBox .titileMain::before {
  content: '';
  position: absolute;
  width: 0.4rem;
  height: 0.13333333rem;
  background: #fff;
  top: 50%;
  transform: translateY(-50%);
}

.index .footerWrapper .titleBox .titileMain::before {
  left: -0.53333333rem;
}

.index .footerWrapper .titleBox .titileMain::after {
  right: -0.53333333rem;
}

.index .footerWrapper .titleBox .titleSub {
  font-size: 0.37333333rem;
  margin-top: 0.02666667rem;
}

.index .footerWrapper .images {
  margin: 0.57333333rem 0 0.85333333rem 0;
}

.index .footerWrapper .images .imagesItem {
  margin-bottom: 0.26666667rem;
  border-radius: 0.26666667rem;
}

.index .footerWrapper .beishu {
  width: 100%;
  height: 3.97333333rem;
}

.index .footerWrapper .inspect {
  text-align: center;
}

.index .footerWrapper .inspect .logo {
  width: 1.8rem;
  height: 1.8rem;
  margin-bottom: 0.48rem;
}