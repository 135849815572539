html,
body,
ol,
ul,
li,
h1,
p {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

a,
img,
button,
input,
textarea,
i,
span,
div {
  -webkit-tap-highlight-color: rgba(255,255,255,0);
}

input,
select,
textarea {
  padding: 0;
  border-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  box-sizing: border-box;
  color: #333;
  font-size: 0.18666667rem;
  -webkit-overflow-scrolling: touch;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  background-color: #232628;
}

body *,
body *::before,
body *::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

.clearfix {
  *zoom: 1;
}

.clearfix::after {
  display: table;
  clear: both;
  line-height: 0;
  content: "";
}

.wrap {
  min-width: 17.06666667rem;
}

.content {
  width: 17.06666667rem;
  margin: 0 auto;
}

.safe-area-inset-bottom {
  border-width: constant(safe-area-inset-bottom);
  border-width: env(safe-area-inset-bottom);
  border-bottom-color: #f7f7f7;
  border-bottom-style: solid;
}

.flex_cen_bet {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex_top_bet {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}